<!-- 多联机空调设备 -->
<template>
  <div id="equipment" v-loading="loading" :element-loading-text="$t('airconditiondDevice.a1')">
    <!-- 头部查询栏 -->
    <div class="header">
      <div>
        <!-- 楼层/租户选择 -->
        <a-select v-model="form.region" @change="getSelectionTenantData" style="width: 150px;margin-right:15px">
          <a-select-option v-for="(item, value) in options" :key="value" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <!-- 显示方式切换 -->
        <a-radio-group v-model="value" style="margin-right:15px" @change="displayMethodChange">
          <a-radio-button :value="1">
            <div class="type">
              {{ $t('airconditiondDevice.a2') }}
              <a-icon type="appstore" :style="{ fontSize: '18px', 'margin-left': '5px' }" />
            </div>
          </a-radio-button>
          <a-radio-button :value="2">
            <div class="type">
              {{ $t('airconditiondDevice.a3') }}
              <a-icon type="profile" :style="{ fontSize: '18px', 'margin-left': '5px' }" />
            </div>
          </a-radio-button>
        </a-radio-group>
        <!-- 展开/收起 -->
        <transition name="slide-fade" mode="out-in">
          <a-button-group v-if="value === 1">
            <a-button icon="down" @click="developChange">
              {{ $t('airconditiondDevice.a4') }}
            </a-button>
            <a-button icon="up" @click="packUpChange">
              {{ $t('airconditiondDevice.a5') }}
            </a-button>
          </a-button-group>
        </transition>
      </div>
      <!-- 批量设置 -->
      <a-button type="primary" @click="openBatchSettingsModal"> {{ $t('airconditiondDevice.a6') }} </a-button>
    </div>

    <!-- 卡片显示区域 -->
    <div class="main">
        <!-- 卡片显示 -->
      <transition name="slide-fade" mode="out-in">
        <div class="main-card" v-if="value === 1">
          <a-collapse v-model="developSelect">
            <a-collapse-panel v-for="(item,index) in deviceCardList" :key="`${index}`" :header="item.tenantName">
              <div class="card-content">
                <my-airConditionCard v-for="cardItem in item.airConditionPointGrVoList" :key="cardItem.pointGrId" :data="cardItem" @click="goToDetails(record)"/>
                <div class="empty" v-for="n in 10" :key="'m' + n"></div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </transition>

      <!-- 表格显示 -->
      <transition name="slide-fade" mode="out-in">
        <div class="main-table" v-if="value === 2" v-table-size="tableSize">
          <a-table :columns="columns" :data-source="deviceCardTableList" :scroll="size" :pagination="pagination" bordered size="middle" :rowKey="record => record.pointGrId">
            <!-- 设备状态 -->
            <template slot="status" slot-scope="text, record">
              <div v-if="record.status === 0" class="normalState"> {{ $t('airconditiondDevice.a7') }} </div>
              <div v-if="record.status === 2" class="abnormalState"> {{ $t('airconditiondDevice.a8') }} </div>
              <div v-if="record.status === 8" class="maintenanceStatus"> {{ $t('airconditiondDevice.a34') }} </div>
              <div v-if="record.status === 16" class="alarmState"> {{ $t('airconditiondDevice.a9') }} </div>
            </template>
            <!-- 设置温度 -->
            <template slot="temperatureSignal" slot-scope="text, record">
              <span>{{ record.temperatureSignal.signalValue }}</span>
            </template>
            <!-- 操作 -->
            <template slot="operate" slot-scope="text, record">
              <a-popover placement="left">
                <template slot="content">
                  <a-button type="primary" style="margin-right: 15px;" @click="goToDetails(record)"> {{ $t('airconditiondDevice.a17') }} </a-button>
                  <a-button type="primary" @click="openAirconditionSet(record)"> {{ $t('airconditiondDevice.a45') }} </a-button>
                </template>
                <a> {{ $t('airconditiondDevice.a44') }} </a>
              </a-popover>
            </template>
          </a-table>
        </div>
      </transition>
    </div>

    <!-- 底栏状态说明栏 -->
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item,index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{item.name}}</span>
        </li>
      </ul>
    </div>

    <!-- 表格空调参数设置 -->
    <a-modal :title="$t('airconditiondDevice.a46')" :width="400" centered :visible="airconditionSetVisible" v-drag @cancel="airconditionSetCancel" :destroyOnClose="true" :zIndex="1100">
      <template slot="footer">
        <a-button key="back" @click="airconditionSetCancel"> {{ $t('airconditiondDevice.a18') }} </a-button>
        <a-button key="submit" type="primary" :disabled="batchSettingsDisabled" @click="airconditionSetOk"> {{ $t('airconditiondDevice.a19') }} </a-button>
      </template>
      <div  v-loading="batchLoading" :element-loading-text="$t('airconditiondDevice.a1')">
        <h3 class="deviceName"> {{ $t('airconditiondDevice.a35') }}：{{ deviceName }} </h3>
        <a-row style="margin-bottom: 15px;">
          <a-col :span="12">
            <p>{{ $t('airconditiondDevice.a37') }}：</p>
            <a-switch v-model="airconditionSetForm.siwtch"/>
          </a-col>
          <a-col :span="12">
            <p>{{ $t('airconditiondDevice.a21') }}：</p>
            <a-select v-model="airconditionSetForm.mode" style="width: 100%" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option value="2"> {{ $t('airconditiondDevice.a10') }} </a-select-option>
              <a-select-option value="1"> {{ $t('airconditiondDevice.a11') }} </a-select-option>
              <a-select-option value="3"> {{ $t('airconditiondDevice.a12') }} </a-select-option>
              <a-select-option value="4"> {{ $t('airconditiondDevice.a13') }} </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <p>{{ $t('airconditiondDevice.a38') }}：</p>
            <a-input-number v-model="airconditionSetForm.temperature" :min="16" :max="40" style="margin-right: 10px;"/>
            <span>℃</span>
          </a-col>
          <a-col :span="12">
            <p>{{ $t('airconditiondDevice.a33') }}：</p>
            <a-select v-model="airconditionSetForm.wind" style="width: 100%" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option value="1"> {{ $t('airconditiondDevice.a14') }} </a-select-option>
              <a-select-option value="2"> {{ $t('airconditiondDevice.a15') }} </a-select-option>
              <a-select-option value="3"> {{ $t('airconditiondDevice.a16') }} </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
    </a-modal>

    <!-- 批量设置对话框 -->
    <a-modal :title="$t('airconditiondDevice.a6')" :width="1200" centered :visible="visible" v-drag @cancel="batchSettingsCancel" :destroyOnClose="true">
      <template slot="footer">
        <a-button key="back" @click="batchSettingsCancel"> {{ $t('airconditiondDevice.a18') }} </a-button>
        <a-button key="submit" type="primary" :disabled="batchSettingsDisabled" @click="batchSettingsOk"> {{ $t('airconditiondDevice.a19') }} </a-button>
      </template>
      <div  v-loading="batchLoading" :element-loading-text="$t('airconditiondDevice.a1')">
        <div class="modeSet">
            <a-radio-group v-model="modeValue" style="margin-right: 10px">
              <a-radio :value="1"> {{ $t('airconditiondDevice.a20') }} </a-radio>
              <a-radio :value="2"> {{ $t('airconditiondDevice.a21') }} </a-radio>
              <a-radio :value="3"> {{ $t('airconditiondDevice.a22') }} </a-radio>
              <a-radio :value="4"> {{ $t('airconditiondDevice.a23') }} </a-radio>
            </a-radio-group>
            <a-select v-model="siwtchSelect" style="width: 120px;margin-right: 15px" :disabled="modeValue !== 1">
              <a-select-option value="1"> {{ $t('airconditiondDevice.a24') }} </a-select-option>
              <a-select-option value="0"> {{ $t('airconditiondDevice.a25') }} </a-select-option>
            </a-select>
            <a-select v-model="modeSelect" style="width: 120px;margin-right: 15px" :disabled="modeValue !== 2">
              <a-select-option value="2"> {{ $t('airconditiondDevice.a10') }} </a-select-option>
              <a-select-option value="1"> {{ $t('airconditiondDevice.a11') }} </a-select-option>
              <a-select-option value="3"> {{ $t('airconditiondDevice.a12') }} </a-select-option>
              <a-select-option value="4"> {{ $t('airconditiondDevice.a13') }} </a-select-option>
            </a-select>
            <a-select v-model="windSpeedSelect" style="width: 120px;margin-right: 15px" :disabled="modeValue !== 3">
              <a-select-option value="1"> {{ $t('airconditiondDevice.a14') }} </a-select-option>
              <a-select-option value="2"> {{ $t('airconditiondDevice.a15') }} </a-select-option>
              <a-select-option value="3"> {{ $t('airconditiondDevice.a16') }} </a-select-option>
            </a-select>
            <a-input-number id="inputNumber" :value="temperatureValue" :min="16" :max="40" :disabled="modeValue !== 4" style="margin-right:5px"/>
            <span>℃</span>
        </div>
        <a-table :row-selection="{ 
          selectedRowKeys: selectedRowKeys, 
          onChange: onSelectChange, 
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.status === 2 || record.status === 8
            },
          }) 
          }" 
          :data-source="deviceCardTableList" 
          :pagination="pagination" bordered 
          :columns="batchColumns" 
          :scroll="{ x: 1000, y: 350 }" 
          :rowKey="record => record.pointGrId"
        >
          <!-- 设备状态 -->
          <template slot="status" slot-scope="text, record">
            <div v-if="record.status === 0" class="normalState"> {{ $t('airconditiondDevice.a7') }} </div>
            <div v-if="record.status === 2" class="abnormalState">{{ $t('airconditiondDevice.a8') }}</div>
            <div v-if="record.status === 8" class="maintenanceStatus">{{ $t('airconditiondDevice.a34') }}</div>
            <div v-if="record.status === 16" class="alarmState">{{ $t('airconditiondDevice.a9') }}</div>
          </template>
        </a-table>
      </div>
    </a-modal>

    <!--  批量设置失败列表 -->
    <a-modal :title="$t($t('airconditiondDevice.a41'))" :width="500" centered :visible="failVisible" v-drag :pagination="false" :destroyOnClose="true" @cancel="failListCancel">
      <template slot="footer">
        <a-button key="submit" type="primary" ghost @click="failListCancel"> {{ $t('airconditiondDevice.a18') }} </a-button>
      </template>
      <a-table :data-source="batchSettingsFailList" :pagination="pagination" :columns="batchFailColumns" :scroll="{ y: 350 }" :rowKey="record => record.pointGrId"/>
    </a-modal>

    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import airConditionCard from './components/air-conditionCard'
import { getDeviceListDetail, setAirconditionDeviceApi } from '../../api/device'
import { getTenantList } from '../../api/door'
import cameraDialog from '../monitoring/cameraDialog.vue'
import moment from 'moment'

export default {
  data() {
    return {
      loading:false,
      form: {
        region: 0,
        deviceName: "",
      },
      options: [],
      developSelect: ['0'],
      deviceCardList: [],
      deviceCardTableList: [],
      size: { x: 1200, y: 240 },
      icon: [
        {
          name: this.$t('airconditiondDevice.a7'),
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: this.$t('airconditiondDevice.a8'),
          url: require("../../../public/images/door/equipment03.png"),
        },
        {
          name: this.$t('airconditiondDevice.a34'),
          url: require("../../../public/images/door/equipment06.png"),
        },
        {
          name: this.$t('airconditiondDevice.a9'),
          url: require("../../../public/images/door/equipment05.png"),
        },
      ],
      starDate:new moment().startOf('day'),
      endDate:new moment(),
      visible:false,
      value: 1,
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ['10', '30', '50', '100'], //每页中显示的数据
        showTotal: (total) => `${total} ${this.$t('airconditiondDevice.a31')}`, //分页中显示总的数据
        showQuickJumper: false, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current
          this.pagination.pageSize = size
        }
      },
      columns:[
        {
          title: 'NO',
          dataIndex: 'NO',
          customRender: (text, record, index) =>{
            const obj = {
              children: `${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`
            }
            return obj
          },
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a35'),
          dataIndex: 'pointGrName',
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a36'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 150,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a37'),
          dataIndex: 'switch',
          customRender: (text, record, index) =>{
            const switchValue = record.switchingSignal.signalValue;
            if(switchValue === '0'){
              return this.$t('airconditiondDevice.a25');
            }else{
              return this.$t('airconditiondDevice.a24');
            }
          },
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a38'),
          dataIndex: 'temperatureSignal',
          scopedSlots: { customRender: 'temperatureSignal' },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a39'),
          dataIndex: 'roomTemperatureSignal',
          customRender: (text, record, index) =>{
            return record.roomTemperatureSignal.signalValue
          },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a21'),
          dataIndex: 'modeSignal',
          customRender: (text, record, index) =>{
            const modeValue = record.modeSignal.signalValue;
            if(modeValue === '1'){
              return this.$t('airconditiondDevice.a11');
            }else if(modeValue === '2'){
              return this.$t('airconditiondDevice.a10');
            }else if(modeValue === '3'){
              return this.$t('airconditiondDevice.a12');
            }else if(modeValue === '4'){
              return this.$t('airconditiondDevice.a13');
            }
          },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a22'),
          dataIndex: 'windSpeedSignal',
          width: 120,
          customRender: (text, record, index) =>{
            const windValue = record.windSpeedSignal.signalValue;
            if(windValue === '1'){
              return this.$t('airconditiondDevice.a14');
            }else if(windValue === '2'){
              return this.$t('airconditiondDevice.a15');
            }else if(windValue === '3'){
              return this.$t('airconditiondDevice.a16');
            }
          },
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a40'),
          dataIndex: 'operate',
          scopedSlots: { customRender: 'operate' },
          width: 130,
          fixed: 'right',
          align: 'center'
        }
      ],
      batchColumns:[
        {
          title: 'NO',
          dataIndex: 'NO',
          customRender: (text, record, index) =>{
            const obj = {
              children: `${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`
            }
            return obj
          },
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a35'),
          dataIndex: 'pointGrName',
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a36'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 150,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a37'),
          dataIndex: 'switch',
          customRender: (text, record, index) =>{
            const switchValue = record.switchingSignal.signalValue;
            if(switchValue === '0'){
              return this.$t('airconditiondDevice.a25');
            }else{
              return this.$t('airconditiondDevice.a24');
            }
          },
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a38'),
          dataIndex: 'temperatureSignal',
          customRender: (text, record, index) =>{
            return record.temperatureSignal.signalValue;
          },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a39'),
          dataIndex: 'roomTemperatureSignal',
          customRender: (text, record, index) =>{
            return record.roomTemperatureSignal.signalValue;
          },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a21'),
          dataIndex: 'modeSignal',
          customRender: (text, record, index) =>{
            const mode = record.modeSignal.signalValue;
            switch(mode){
              case '1':
                return this.$t('airconditiondDevice.a11');
                break;
              case '2':
                return this.$t('airconditiondDevice.a10');
                break;
              case '3':
                return this.$t('airconditiondDevice.a12');
                break;
              case '4':
                return this.$t('airconditiondDevice.a13');
                break;
              default:
                return '--'
                break;
            } 
          },
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a22'),
          dataIndex: 'windSpeedSignal',
          customRender: (text, record, index) =>{
            const windSpeed = record.windSpeedSignal.signalValue;
            switch(windSpeed){
              case '1':
                return this.$t('airconditiondDevice.a14');
                break;
              case '2':
                return this.$t('airconditiondDevice.a15');
                break;
              case '3':
                return this.$t('airconditiondDevice.a16');
                break;
              default:
                return '--'
                break;
            } 
          },
          width: 120,
          align: 'center'
        }
      ],
      batchSettingsDisabled: false,
      batchLoading: false,
      modeValue: 1,
      siwtchSelect: '0',
      modeSelect: '2',
      windSpeedSelect: '1',
      temperatureValue: 26,
      selectedRowKeys: [],
      selectedRows: [],
      failVisible: false,
      batchSettingsFailList: [],
      batchFailColumns: [
        {
          title: 'NO',
          dataIndex: 'NO',
          customRender: (text, record, index) =>{
            const obj = {
              children: `${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`
            }
            return obj
          },
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: this.$t('airconditiondDevice.a35'),
          dataIndex: 'pointGrName',
          align: 'center'
        }
      ],
      cameraId:0,
      vdialog: false,
      vmode:'1',
      airconditionSetVisible: false,
      airconditionSetForm: {
        siwtch: false,
        temperature: 25,
        mode: '1',
        wind: '1'
      },
      deviceName: undefined,
      airconditionSetCopy: {}
    };
  },
  created() {
    // 页面初始化，初始化-->楼层/租户选择列表
    this.loading = true;
    getTenantList(this.$route.query.id)
    .then((res) => {
      // console.log(res)
      let { data } = res;
      this.options = data.sort((a, b) => a.id - b.id);
      const arr = data.filter((item)=>{ if(item.tenantType === 3){ return item } });
      if(arr.length !== 0){
        this.form.region = arr[0].id;
      }else{
        this.form.region = data[0].id;
      }
      this.getDeviceCardList();
    })
    .catch((err) => {
      console.log(err);
    })
  },
  methods: {
    moment,
    // 楼层/租户选择数据获取
    getSelectionTenantData(){
      this.getDeviceCardList();
    },
    // 获取设备详情列表数据（卡片数据/表格数据）
    getDeviceCardList(){
      this.loading = true;
      this.developSelect = ['0'];
      const deviceKind = 2;
      const deviceType = 150;
      const siteId = this.$route.query.id;
      const tenantId = this.form.region;
      const params = { deviceKind, deviceType, siteId, tenantId };
      getDeviceListDetail(params)
      .then(res => {
        // console.log('多联机空调--设备卡片数据获取成功：', res);
        const { data } = res;
        const mergedArray = data.reduce((accumulator, item) => { return accumulator.concat(item.airConditionPointGrVoList)}, []);
        this.deviceCardList = data;
        this.deviceCardTableList = mergedArray;
        // console.log('表格数据', mergedArray)
        this.loading = false;
      })
      .catch(err => {
        console.log('多联机空调--设备卡片数据获取出错：', err);
        this.loading = false;
      })
    },
    // 表格切换至图标显示时折叠窗口展开设置
    displayMethodChange(e){
      if(e.target.value === 1){ this.developSelect = ['0'] }
    },
    // 展开方法
    developChange(){
      const deviceCardList = this.deviceCardList;
      const developSelect = this.developSelect;
      if(deviceCardList.length != developSelect.length){
        if(deviceCardList.length > 0){
          this.developSelect.push('0')
          setTimeout(() => {
            for(let i = 1; i < deviceCardList.length; i++){
              this.developSelect.push(`${i}`) 
            }
          }, 800)
        }
      }
    },
    // 收起方法
    packUpChange(){
      this.developSelect = []
    },
    // 表格高度计算
    tableSize(){
      const indexDom = document.getElementById('equipment');
      if (this.value === 2) {
        const tableMain = indexDom.getElementsByClassName("main-table")[0];
        const height = tableMain.clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 96.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 打开批量设置对话框
    openBatchSettingsModal(){
      this.visible = true;
    },
    // 关闭批量对话框
    batchSettingsCancel(){
      this.visible = false;
      this.modeValue = 1;
      this.siwtchSelect = '0';
      this.modeSelect = '2';
      this.windSpeedSelect = '1';
      this.temperatureValue = 26;
      this.selectedRowKeys = [];
    },
    // 多选数据赋值
    onSelectChange(selectedRowKeys, selectedRows){
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 批量数据设置请求 
    batchSettingsOk(){
      const length = this.selectedRowKeys.length;
      if(length === 0){
        this.$warning({ title: this.$t('airconditiondDevice.a43'), content: this.$t('airconditiondDevice.a42'), centered: true });
      }else{
        this.batchLoading = true;
        this.batchSettingsDisabled = true;
        const modeValue = this.modeValue;
        const siwtchSelect = this.siwtchSelect;
        const modeSelect = this.modeSelect;
        const windSpeedSelect = this.windSpeedSelect;
        const temperatureValue = this.temperatureValue.toFixed(1).toString();
        const airconditionData = this.selectedRows;
        let batchAirconditionData = undefined;
        if(modeValue === 1){
          batchAirconditionData = airconditionData.map(item => {
            const data = JSON.parse(JSON.stringify({ pointGrId: item.pointGrId, switchingSignal: item.switchingSignal, pointGrName: item.pointGrName }));
            data.switchingSignal.signalValue = siwtchSelect;
            return data;
          })
        }else if(modeValue === 2){
          batchAirconditionData = airconditionData.map(item => {
            const data = JSON.parse(JSON.stringify({ pointGrId: item.pointGrId, modeSignal: item.modeSignal, pointGrName: item.pointGrName }));
            data.modeSignal.signalValue = modeSelect;
            return data;
          })
        }else if(modeValue === 3){
          batchAirconditionData = airconditionData.map(item => {
            const data = JSON.parse(JSON.stringify({ pointGrId: item.pointGrId, windSpeedSignal: item.windSpeedSignal, pointGrName: item.pointGrName }));
            data.windSpeedSignal.signalValue = windSpeedSelect;
            return data;
          })
        }else if(modeValue === 4){
          batchAirconditionData = airconditionData.map(item => {
            const data = JSON.parse(JSON.stringify({ pointGrId: item.pointGrId, temperatureSignal: item.temperatureSignal, pointGrName: item.pointGrName }));
            data.temperatureSignal.signalValue = temperatureValue;
            return data;
          })
        }
        setAirconditionDeviceApi(batchAirconditionData)
        .then(res => {
          // console.log(res)
          const { data, errorCode, msg } = res;
          // 
          if(errorCode === '00'){
            this.$message.success(msg);
            if(modeValue === 1){
              airconditionData.forEach(item => {
                item.switchingSignal.signalValue = siwtchSelect;
              })
            }else if(modeValue === 2){

              airconditionData.forEach(item => {
                item.modeSignal.signalValue = modeSelect;
              })
            }else if(modeValue === 3){
              airconditionData.forEach(item => {
                item.windSpeedSignal.signalValue = windSpeedSelect;
              })
            }else if(modeValue === 4){
              airconditionData.forEach(item => {
                item.temperatureSignal.signalValue = temperatureValue + '℃';
              })
            }
          }else{
            this.failVisible = true;
            if(modeValue === 1){
              airconditionData.forEach(item => {
                const isExist = data.findIndex(items => { return items.pointGrId === item.pointGrId });
                if(isExist === -1){
                  item.switchingSignal.signalValue = siwtchSelect;
                }
              })
            }else if(modeValue === 2){
              airconditionData.forEach(item => {
                const isExist = data.findIndex(items => { return items.pointGrId === item.pointGrId });
                if(isExist === -1){
                  item.modeSignal.signalValue = modeSelect;
                }
              })
            }else if(modeValue === 3){
              airconditionData.forEach(item => {
                const isExist = data.findIndex(items => { return items.pointGrId === item.pointGrId });
                if(isExist === -1){
                  item.windSpeedSignal.signalValue = windSpeedSelect;
                }
              })
            }else if(modeValue === 4){
              airconditionData.forEach(item => {
                const isExist = data.findIndex(items => { return items.pointGrId === item.pointGrId });
                if(isExist === -1){
                  item.temperatureSignal.signalValue = temperatureValue + '℃';
                }
              })
            }
          }
          this.batchSettingsFailList = data;
          this.batchLoading = false;
          this.batchSettingsDisabled = false;
        })
        .catch(err => {
          console.log(err)
          this.batchLoading = false;
          this.batchSettingsDisabled = false;
        })
      }
    },
    // 失败列表确认关闭失败列表对话框
    failListCancel(){
      this.failVisible = false;
    },
    // 跳转至详情页
    goToDetails(airconditionData){
      this.$router.push({
          path: '/homepage/air-condition/airConditionDetails',
          query: airconditionData.airConditionParamVo
      });
    },
    // 打开空调设置对话框
    openAirconditionSet(airconditionData){
      // console.log(airconditionData)
      const status = airconditionData.status;
      if(status === 0 || status === 16){
        this.airconditionSetVisible = true;
        const siwtch = airconditionData.switchingSignal.signalValue === '0' ? false : true;
        const temperature = Number(airconditionData.temperatureSignal.signalValue.slice(0, airconditionData.temperatureSignal.signalValue.indexOf('℃')));
        const mode = airconditionData.modeSignal.signalValue;
        const wind = airconditionData.windSpeedSignal.signalValue;
        const deviceName = airconditionData.pointGrName;
        this.airconditionSetForm.siwtch = siwtch;
        this.airconditionSetForm.temperature = temperature;
        this.airconditionSetForm.mode = mode;
        this.airconditionSetForm.wind = wind;
        this.deviceName = deviceName;
        this.airconditionSetCopy = airconditionData;
      }else{
        this.$warning({ title: this.$t('airconditiondDevice.a27'), content: this.$t('airconditiondDevice.a28'), centered: true });
      }
    },
    // 关闭空调设置对话框
    airconditionSetCancel(){
      this.airconditionSetVisible = false;
    },
    // 表格空调参数设置
    airconditionSetOk(){
      this.batchLoading = true;
      this.batchSettingsDisabled = true;
      const siwtch = this.airconditionSetForm.siwtch;
      if(!this.airconditionSetForm.temperature){ this.airconditionSetForm.temperature = 26 };
      const temperature = this.airconditionSetForm.temperature;
      const mode = this.airconditionSetForm.mode;
      const wind = this.airconditionSetForm.wind;
      const airconditionData = JSON.parse(JSON.stringify(this.airconditionSetCopy));
      delete airconditionData.airConditionParamVo;
      delete airconditionData.roomTemperatureSignal;
      delete airconditionData.status;
      delete airconditionData.pointGrName;
      airconditionData.switchingSignal.signalValue = siwtch ? '1' : '0';
      airconditionData.temperatureSignal.signalValue = temperature + '℃';
      airconditionData.modeSignal.signalValue = mode;
      airconditionData.windSpeedSignal.signalValue = wind;
      setAirconditionDeviceApi([airconditionData])
      .then(res => {
        // console.log(res);
        const { data, errorCode, msg } = res;
        if(errorCode === '00'){
          this.$message.success(msg);
          this.airconditionSetVisible = false;
          this.airconditionSetCopy.switchingSignal.signalValue = siwtch ? '1' : '0';
          this.airconditionSetCopy.temperatureSignal.signalValue = temperature + '℃';
          this.airconditionSetCopy.modeSignal.signalValue = mode;
          this.airconditionSetCopy.windSpeedSignal.signalValue = wind;
        }else{
          this.$message.error(msg);
        }
        this.batchLoading = false;
        this.batchSettingsDisabled = false;
      })
      .catch(err => {
        console.log(err);
        this.batchLoading = false;
        this.batchSettingsDisabled = false;
      })
    },
    // 卡片视频按钮点击回调函数
    hasVideoChange(data){
      this.vdialog = true
      this.cameraId = data
    }
  },
  components: {
    'my-airConditionCard': airConditionCard,
    'camera-dialog':cameraDialog
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#equipment {
  width: 100%;
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.85);
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  white-space: nowrap;
  overflow: hidden;
}
.type{
  display: flex;
  align-items: center;
}
.main {
  position: relative;
  width: 100%;
  height: calc(100% - 90px);
  overflow: hidden;
}
.main-card{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
}
.empty {
  width: 200px;
}
.main-table{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
}
.card-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.modeSet{
  margin-bottom: 10px;
}
.normalState{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #31b855;
  border-radius: 3px;
  color: #31b855;
}
.abnormalState{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #ea5514;
  border-radius: 3px;
  color: #ea5514;
}
.maintenanceStatus{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #adadad;
  border-radius: 3px;
  color: #adadad;
}
.deviceName{
  margin-bottom: 20px;
}
.alarmState{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #f8b72f;
  border-radius: 3px;
  color: #f8b72f;
}
.offStatus{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #f8b72f;
  border-radius: 3px;
  color: #da0808;
}
.onState{
  display: inline-block;
  padding: 1px 10px;
  border: 1px solid #31b855;
  border-radius: 3px;
  color: #31b855;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>