<!-- 多联机空调--设备卡片 -->
<template>
  <div id="air-conditioningCard" v-loading="loading" :element-loading-text="$t('airconditiondDevice.a1')" element-loading-background="rgba(0, 0, 0, 0.6)" @click="goToDetails(data)">
    <!-- 卡片上部分 -->
    <div class="card-top">
      <!-- 开关 -->
      <div class="switch">
        <div>
          <a-button shape="round" size="small" style="width: 35px;padding-top: 2px;" @click.stop="switchChange">
            <a-icon type="poweroff" :class="switchChecked"/>
          </a-button>
        </div>
        <div></div>
      </div>
      <!-- 空调状态信息显示 -->
      <div class="statusDisplay">
        <div class="statusInof">
          <!-- 空调状态显示图片 -->
          <div>
            <el-tooltip :open-delay="400" effect="dark" :content="getStatusFont" placement="right">
              <img :src="getStatusImage"/>
            </el-tooltip>
          </div>
          <!-- 空调设置温度显示 -->
          <div class="setTemperature">
            <span>{{ data.temperatureSignal.signalValue }}</span>
          </div>
          <!-- 空调室温显示 -->
          <div class="indoorTemperature">
            <p>{{ $t('airconditiondDevice.a26') }}</p>
            <span>{{ data.roomTemperatureSignal.signalValue }}</span>
          </div>
        </div>
        <!-- 空调名称 -->
        <div class="airconditionName"> {{ data.pointGrName }} </div>
      </div>
    </div>

    <!-- 卡片下部分 -->
    <!-- 空调模式开关 -->
    <div class="modeSwitch">
      <a-button-group style="width: 100%;">
        <!-- 制冷 -->
        <el-tooltip :open-delay="400" effect="dark" :content="$t('airconditiondDevice.a10')" placement="top-start">
          <a-button style="width: 40px;" @click.stop="modeChange('2')">
            <img :src="refrigeration" height="25" />
          </a-button>
        </el-tooltip>
        <!-- 制热 -->
        <el-tooltip :open-delay="400" effect="dark" :content="$t('airconditiondDevice.a11')" placement="top-start">
          <a-button style="width: 41px;" @click.stop="modeChange('1')">
            <img :src="heat" height="25"/>
          </a-button>
        </el-tooltip>
        <!-- 送风 -->
        <el-tooltip :open-delay="400" effect="dark" :content="$t('airconditiondDevice.a12')" placement="top-start">
          <a-button style="width: 41px;" @click.stop="modeChange('3')">
            <img :src="blow" height="19"/>
          </a-button>
        </el-tooltip>
        <!-- 抽湿 -->
        <el-tooltip :open-delay="400" effect="dark" :content="$t('airconditiondDevice.a13')" placement="top-start">
          <a-button style="width: 41px;" @click.stop="modeChange('4')">
            <img :src="ddehumidification"  height="21"/>
          </a-button>
        </el-tooltip>
        <!-- 风量 -->
        <el-tooltip :open-delay="400" effect="dark" :content="windSpeedTitle" placement="top-start">
          <a-button style="width: 41px;" @click.stop="windSpeedChange">
            <img :src="windSpeed" height="22"/>
          </a-button>
        </el-tooltip>
      </a-button-group>
    </div>
  </div>
</template>

<script>
import { setAirconditionDeviceApi } from '../../../api/device'

export default {
  props:{
    data:{
      type:Object
    }
  },
  data(){
    return{
      loading: false,
      airconditionData: JSON.parse(JSON.stringify(this.data))
    }
  },
  computed:{
    // 开关状态
    switchChecked(){
      const checked = this.airconditionData.switchingSignal.signalValue;
      const status = this.airconditionData.status;
      if(status === 0 || status === 16){
        if(checked === '1'){
          return 'switch-no';
        }else{
          return 'switch-off';
        }
      }else{
        return 'switch-off';
      }
    },
    // 获取空调状态图片
    getStatusImage(){
      const status = this.airconditionData.status;
      switch(status){
        case 0:
          return require('../../../../public/images/air/normal.png')
          break;
        case 2:
          return require('../../../../public/images/air/breakdown.png')
          break;
        case 8:
          return require('../../../../public/images/air/maintenance.png')
          break;
        case 16:
          return require('../../../../public/images/air/abnormal.png')
          break;
        default:
          return require('../../../../public/images/air/normal.png')
          break;
      }
    },
    // 空调状态图片显示文字提示
    getStatusFont(){
      const status = this.airconditionData.status;
      switch(status){
        case 0:
          return this.$t('airconditiondDevice.a7')
          break;
        case 2:
          return this.$t('airconditiondDevice.a8')
          break;
        case 8:
          return this.$t('airconditiondDevice.a34')
          break;
        case 16:
          return this.$t('airconditiondDevice.a9')
          break;
        default:
          return this.$t('airconditiondDevice.a7')
          break;
      }
    },
    // 制冷图标
    refrigeration(){
      const signalValue = this.airconditionData.modeSignal.signalValue;
      if(signalValue === '2'){
        return require('../../../../public/images/air/cold_on_img.png');
      }else{
        return require('../../../../public/images/air/cold_off_img.png');
      }
    },
    // 制热图标
    heat(){
      const signalValue = this.airconditionData.modeSignal.signalValue;
      if(signalValue === '1'){
        return require('../../../../public/images/air/warm_on_img.png');
      }else{
        return require('../../../../public/images/air/warm_off_img.png');
      }
    },
    // 送风图标
    blow(){
      const signalValue = this.airconditionData.modeSignal.signalValue;
      if(signalValue === '3'){
        return require('../../../../public/images/air/blow_on_img.png');
      }else{
        return require('../../../../public/images/air/blow_off_img.png');
      }
    },
    // 抽湿图标
    ddehumidification(){
      const signalValue = this.airconditionData.modeSignal.signalValue;
      if(signalValue === '4'){
        return require('../../../../public/images/air/dry_on_img.png');
      }else{
        return require('../../../../public/images/air/dry_off_img.png');
      }
    },
    // 风速图标
    windSpeed(){
      const signalValue = this.airconditionData.windSpeedSignal.signalValue;
      if(signalValue === '1'){
        return require('../../../../public/images/air/wind_weak.png');
      }else if(signalValue === '2'){
        return require('../../../../public/images/air/wind_medium.png');
      }else if(signalValue === '3'){
        return require('../../../../public/images/air/wind_strong.png');
      }
    },
    // 风速标题
    windSpeedTitle(){
      const signalValue = this.airconditionData.windSpeedSignal.signalValue;
      if(signalValue === '1'){
        return this.$t('airconditiondDevice.a14');
      }else if(signalValue === '2'){
        return this.$t('airconditiondDevice.a15');
      }else if(signalValue === '3'){
        return this.$t('airconditiondDevice.a16');
      }
    }
  },
  methods:{
    // 空调开关状态修改
    switchChange(){
      const airconditionData = JSON.parse(JSON.stringify(this.airconditionData));
      const status = airconditionData.status;
      if(status === 0 || status === 16){
        this.loading = true;
        const switchStatus = airconditionData.switchingSignal.signalValue;
        let switchChangeValue = switchStatus === '0' ? '1' : '0';
        let setAirconditionData = [{ pointGrId: airconditionData.pointGrId, switchingSignal: airconditionData.switchingSignal }];
        setAirconditionData[0].switchingSignal.signalValue = switchChangeValue;
        // console.log(setAirconditionData)
        setAirconditionDeviceApi(setAirconditionData)
        .then(res => {
          // console.log(res);
          const { data, errorCode, msg } = res;
          if(errorCode === '00'){
            this.$message.success(msg);
            this.airconditionData.switchingSignal.signalValue = switchChangeValue;
            this.data.switchingSignal.signalValue = switchChangeValue;
          }else{
            this.$message.error(msg);
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        })
      }else{
        this.$warning({ title: this.$t('airconditiondDevice.a27'), content: this.$t('airconditiondDevice.a28'), centered: true });
      }
    },
    // 空调模式状态设置（制冷/制热/送风/抽湿）
    modeChange(signalValue){
      const airconditionData = JSON.parse(JSON.stringify(this.airconditionData));
      const switchStatus = airconditionData.switchingSignal.signalValue;
      if(switchStatus === '1'){
        this.loading = true;
        let setAirconditionData = [{ pointGrId: airconditionData.pointGrId, modeSignal: airconditionData.modeSignal }];
        setAirconditionData[0].modeSignal.signalValue = signalValue
        // console.log(setAirconditionData);
        setAirconditionDeviceApi(setAirconditionData)
        .then(res => {
          // console.log(res);
          const { data, errorCode, msg } = res;
          if(errorCode === '00'){
            this.$message.success(msg);
            this.airconditionData.modeSignal.signalValue = signalValue;
            this.data.modeSignal.signalValue = signalValue;
          }else{
            this.$message.error(msg);
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err)
          this.loading = false;
        })
      }else{
        this.$warning({ title: this.$t('airconditiondDevice.a29'), content: this.$t('airconditiondDevice.a30'), centered: true });
      }
    },
    // 风速状态设置
    windSpeedChange(){
      const airconditionData = JSON.parse(JSON.stringify(this.airconditionData));
      const switchStatus = airconditionData.switchingSignal.signalValue;
      let signalValue = '';
      if(switchStatus === '1'){
        this.loading = true;
        let setAirconditionData = [{ pointGrId: airconditionData.pointGrId, windSpeedSignal: airconditionData.windSpeedSignal }];
        const windSpeed = airconditionData.windSpeedSignal.signalValue;
        if(windSpeed === '1'){
          setAirconditionData[0].windSpeedSignal.signalValue = '2';
          signalValue = '2';
        }else if(windSpeed === '2'){
          setAirconditionData[0].windSpeedSignal.signalValue = '3';
          signalValue = '3';
        }else if(windSpeed === '3'){
          setAirconditionData[0].windSpeedSignal.signalValue = '1';
          signalValue = '1';
        }
        // console.log(setAirconditionData);
        setAirconditionDeviceApi(setAirconditionData)
        .then(res => {
          // console.log(res);
          const { data, errorCode, msg } = res;
          if(errorCode === '00'){
            this.$message.success(msg);
            this.airconditionData.windSpeedSignal.signalValue = signalValue;
            this.data.windSpeedSignal.signalValue = signalValue;
          }else{
            this.$message.error(msg);
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err)
          this.loading = false;
        })
      }else{
        this.$warning({ title: this.$t('airconditiondDevice.a29'), content: this.$t('airconditiondDevice.a32'), centered: true });
      }
    },
    // 跳转至详情页
    goToDetails(airconditionData){
    console.log('niaho', airconditionData)
    this.$router.push({
        path: '/homepage/air-condition/airConditionDetails',
        query: airconditionData.airConditionParamVo
    });
  },
  }
}
</script>

<style scoped>
#air-conditioningCard{
  width: 200px;
  height: 150px;
  background-color: #f2f4f7;
  margin: 0px 0px 15px 0px;
  color: #000000;
  cursor:pointer;
  transition: 200ms;
  overflow: hidden;
}
.card-top{
  height: calc(100% - 32px);
  border: 1px solid rgba(12, 12, 12, 0.15);
  border-bottom: none;
  padding: 5px;
  border-radius: 3px 3px 0px 0px;
}
.card-top:hover{
  border: 1px solid #7682ce;
}
.switch{
  display: flex;
  padding-left: 5px;
}
.switch-no{
  font-size: 16px;
  color: rgb(5, 191, 55);
}
.switch-off{
  font-size: 16px;
  color: rgba(12, 12, 12, 0.85);
}
.statusInof{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setTemperature{
  padding-right: 5px;
  font-size: 24px;
}
.indoorTemperature{
  padding: 5px 5px 0px 0px;
  vertical-align:middle;
}
.airconditionName{
  padding: 0px 5px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
p{
  margin-bottom: 0px;
}
.modeSwitch{
  width: 100%;
  overflow: hidden;
}
div /deep/.ant-btn{
  padding: 0px;
}
div /deep/.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only){
  padding-left: 15px;
}
</style>